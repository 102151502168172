<template>
  <nav
    class="navbar navbar-expand-lg navbar-light header-root"
    :class="{
      'unauth-header-root': !userData && layoutType === 'default',
      'unauth-header-root-desktop-login': $route.path == '/desktop-login',
      'p-0': userData && $route.path !== '/desktop-login',
      'info-layout-header-root': layoutType === 'info',
      'desktop-login-header': $route.path == '/desktop-login'
    }"
  >
    <div
      class="container-fluid header-content d-flex flex-row flex-nowrap py-0"
      :class="{ 'p-0': !userData, 'auth-header-content': userData }"
    >
      <NuxtLink
        v-if="!customHeader"
        :class="{ 'cursor-default': userData?.launchType === 'lti' }"
        aria-label="Cambridge Logo"
        class="navbar-brand p-0"
        tabindex="0"
        @click="userData?.launchType === 'lti' ? '' : navigateToApp()"
      >
        <ResponsiveImage
          v-if="$route.path == '/desktop-login'"
          file="app-logo.webp"
          :img="imagesProps['cambridge-logo']"
          pc-image
        />
        <!-- remove this image when rebranding is released on LIVE for OB -->
        <ResponsiveImage
          v-else
          file="cambridge-logo-v1.webp"
          :img="imagesProps['cambridge-logo']"
          pc-image
        />
      </NuxtLink>
      <template v-if="customHeader">
        <slot />
      </template>
      <div
        v-if="
          $route.path != '/desktop-login' &&
          $route.path != '/home' &&
          $route.path != '/' &&
          userData
        "
        class="navbar-nav d-flex flex-row align-items-center h-100"
      >
        <div class="navbar-nav d-flex flex-row align-items-center h-100">
          <NuxtLink
            v-if="userData?.launchType !== 'lti'"
            external
            :prefetch="false"
            qid="cFooter-6"
            :to="getHelpPagePath(runtimeConfig.app.appEnv)"
            target="_blank"
            class="btn btn-main-1 btn-help"
            aria-label="Help"
          >
            <ClientOnly>
              <nuxt-icon
                loading="lazy"
                name="fa6-regular.circle-question"
                class="fa-circle-question"
                aria-hidden="true"
              />
            </ClientOnly>
            <p>{{ $t('_COMMON_ACROSS_MODULES.HEADER.HELP') }}</p>
          </NuxtLink>
          <LazyServiceNotification />
        </div>
        <div class="dropdown position-static">
          <button
            id="dropdownMenuButtonUserProfile"
            class="btn btn-secondary dropdown-toggle profile-dropdown d-flex align-items-center justify-content-center"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <ClientOnly>
              <nuxt-icon
                loading="lazy"
                name="nemo-user"
                class="user-profile-icon d-flex align-items-center"
                aria-hidden="true"
              />
            </ClientOnly>
            <span>{{ userData?.firstName }}</span>
            <ClientOnly>
              <nuxt-icon
                id="angle-down-arrow"
                loading="lazy"
                name="fa-solid.angle-down"
                class="fa-angle-down ms-1"
                aria-hidden="true"
              />
            </ClientOnly>
          </button>

          <ul
            class="dropdown-menu dropdown-menu-end dropdown-menu-user-profile"
            aria-labelledby="dropdownMenuButtonUserProfile"
          >
            <li
              class="dropdown-item dropdown-toggle d-flex align-items-center justify-content-center myProfileName"
            >
              <ClientOnly>
                <nuxt-icon
                  loading="lazy"
                  name="nemo-user"
                  class="user-profile-icon d-flex align-items-center"
                  aria-hidden="true"
                />
              </ClientOnly>
              <span>{{ userData?.firstName }}</span>
              <ClientOnly>
                <nuxt-icon
                  id="angle-up-arrow"
                  loading="lazy"
                  name="fa-solid.angle-up"
                  class="fa-angle-up"
                  aria-hidden="true"
                />
              </ClientOnly>
            </li>
            <div class="dropdown-divider" />
            <template v-if="userData?.launchType !== 'lti'">
              <li class="dropdown-item">
                <button
                  qid="cHeader-5"
                  class="dropdown-item-btn"
                  @click="navigateToDashboardApp(['my-profile'])"
                >
                  {{ $t('_COMMON_ACROSS_MODULES.HEADER.MY_PROFILE') }}
                </button>
              </li>
              <div class="dropdown-divider" />
              <li v-if="userData?.role == 'teacher'" class="dropdown-item">
                <button
                  qid="cHeader-6"
                  class="dropdown-item-btn"
                  @click="
                    navigateToDashboardApp(
                      ['teacher', 'startteaching', 'joinSchool'],
                      { queryParams: { joinAnotherInstitute: true } }
                    )
                  "
                >
                  {{ $t('_COMMON_ACROSS_MODULES.HEADER.JOIN_NEW_SCHOOL') }}
                </button>
              </li>
              <div
                v-if="userData?.role == 'teacher'"
                class="dropdown-divider"
              />
              <li class="dropdown-item-logout">
                <button
                  qid="cHeader-7"
                  class="btn btn-xs btn-white-bordered logout"
                  @click="logout()"
                >
                  {{ $t('_COMMON_ACROSS_MODULES.HEADER.LOGOUT') }}
                </button>
              </li>
            </template>
            <template v-else>
              <li class="dropdown-item-logout">
                <button
                  qid="cHeader-7"
                  class="btn btn-xs btn-white-bordered logout"
                  @click="logout()"
                >
                  {{ $t('_COMMON_ACROSS_MODULES.HEADER.LOGOUT') }}
                </button>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div
        v-else-if="userData == false && $route.path != '/desktop-login'"
        class="navbar-nav d-flex flex-row align-items-center h-100"
        :class="{ 'py-2': !userData && layoutType === 'dafault' }"
      >
        <NuxtLink
          id="onboarding-header-login-btn"
          class="btn btn-white btn-xs login-btn ms-1"
          :class="{
            'login-btn-main': $route.path == '/social-login',
            'd-none': $route.path == '/parental-consent',
            'btn-white': $route.path == '/home' || $route.path == '/',
            'btn-white-bordered text-white login-button-other-pages':
              $route.path !== '/home' && $route.path !== '/'
          }"
          @click="getLoginScreen()"
          >{{ $t('ONBOARDING.HOME.ALREADY_REGISTERED') }}
        </NuxtLink>
        <NuxtLink
          v-if="$route.path == '/home' || $route.path == '/'"
          id="onboarding-header-signup-btn"
          to="/regoptions"
          class="btn btn-xs btn-white-bordered signup-btn"
        >
          {{ $t('ONBOARDING.HOME.GET_STARTED') }}
          <ResponsiveImage
            file="long-arrow-right.webp"
            :img="imagesProps['long-arrow-right']"
            pc-image
          />
        </NuxtLink>
        <div class="dropdown">
          <div
            data-bs-toggle="dropdown"
            class="language-dropdown"
            :class="
              ($route.path != '/home' &&
                $route.path != '/' &&
                layoutType !== 'info' &&
                'language-dropdown-other-pages') ||
              (layoutType === 'info' && 'info-layout-language-dropdown')
            "
            tabindex="0"
          >
            <ClientOnly>
              <nuxt-icon
                loading="lazy"
                name="nemo.globe"
                class="text-white nemo-globe"
                aria-hidden="true"
              />
            </ClientOnly>
            <span
              qid="header-10"
              :class="
                ($route.path == '/home' || $route.path == '/') &&
                'd-none-mobile'
              "
              class="text-white font-xs font-weight-normal ms-1"
            >
              {{ locale }}
            </span>
          </div>

          <div
            aria-labelledby="dropdownMenuLink"
            class="dropdown-menu language-btn"
          >
            <div class="dropdown-menu-arrow" />
            <div
              v-for="localee in locales"
              :key="localee.code"
              :class="{ 'selected-item': localee.code == locale }"
              class="text-center lang-dropdown-item"
              @click="changeLang(localee.code)"
            >
              {{ localee.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
const route = useRoute();
const { addQueryParams } = useHttpHelper();
const queryParams = route.query;
const allowedQueryParams = useRuntimeConfig().public.ielts.allowedQueryParams;
const userData = ref();
const runtimeConfig = useRuntimeConfig();
const { getHelpPagePath } = useHelpConfig();
const { navigateToDashboardApp } = useInterAppNavigationService();
const { checkUserAuthenticatedCache, getUserData } = useOnboardingAuth();
onBeforeMount(() => {
  checkUserAuthenticatedCache().then((resp) => {
    if (resp && route.path != '/parental-consent') {
      userData.value = getUserData();
    } else {
      userData.value = false;
    }
  });
});

//implement logout logic
const logout = async () => {
  const { logOut } = useAuthService();
  const { success } = await logOut();
  if (success) {
    navigateTo({ path: '/home' });
  }
};

const { locale, locales, setLocale } = useI18n();
const { showOverlayLoader, hideLoader } = useLoaderService();
const { lang: language } = useLocalStorage();

const changeLang = async (lang) => {
  showOverlayLoader();
  window.localStorage.setItem(language, lang.toLowerCase());
  await setLocale(lang);
  hideLoader();
  if (runtimeConfig.public.gigyaScreens[route.path]) {
    window.location.reload();
  }
};

const props = defineProps({
  uiProps: {
    type: Boolean,
    default: true
  },
  customHeader: {
    type: Boolean,
    default: false
  },
  layoutType: {
    type: String,
    default: 'default'
  }
});
const imagesProps = {
  'cambridge-logo': {
    class: `${(route.path == '/home' || route.path == '/') && 'brand-img-home'} brand-img`,
    alt: 'Cambridge Logo',
    width: 140,
    height: 40
  },
  'long-arrow-right': {
    class: 'd-none d-md-inline',
    'aria-hidden': 'true'
  }
};

const navigateToApp = () => {
  if (route.query.ielts) {
    const redirectUrl = useNavigation().constructMicroAppUrl(
      ['home'],
      useRuntimeConfig().public.microapps.urlContracts.ielts
    );
    window.location.href = redirectUrl;
  } else if (route.path == '/parental-consent') {
    return;
  } else {
    navigateTo(addQueryParams('/home', { queryParams, allowedQueryParams }));
  }
};
const getLoginScreen = () => {
  let screenPath;
  if (location.pathname.includes('/login-primary')) {
    screenPath = '/login-primary';
  } else if (location.pathname.includes('/login-secondary')) {
    screenPath = '/login-secondary';
  } else {
    screenPath = '/login';
  }

  if (document.getElementById('c1-gigya-container')) {
    const screenId = useRuntimeConfig().public.gigyaScreens[screenPath];
    if (window['gigya']) initGigyaScreen('', screenId, 'c1-gigya-container');
    return;
  }
  navigateTo(addQueryParams(screenPath, { queryParams, allowedQueryParams }));
};
</script>
